//main imports
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'
import Navbar from './components/Navbar'
import Home from './pages/Home'
import Store from './pages/Store'
import About from './pages/About'
import Tags from './pages/Tags'
import Product from './pages/Product'
import Contact from './pages/Contact'
import Footer from './components/Footer'
import PrivacyPolicy from './components/PrivacyPolicy'
import CookiePolicy from './components/CookiePolicy'
import ScrollToTop from "./components/ScrollToTop";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

//Bootstrap
import 'bootstrap/dist/css/bootstrap.css'


function App() {
  return (
    <div>
      <Router>
        <ScrollToTop>
          <div>
              <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/store" element={<Store/>}/>
                {/* <Route exact path="/about" element={<About/>}/> */}
                <Route exact path="/tags" element={<Tags/>}/>
                <Route exact path="/tags/:id" element={<Tags/>}/>
                <Route exact path="/product" element={<Product/>}/>
                <Route exact path="/contact" element={<Contact/>}/>
                <Route exact path="/privacy" element={<PrivacyPolicy/>}/>
                <Route exact path="/cookie" element={<CookiePolicy/>}/>
              </Routes>
          </div>
        </ScrollToTop>
      </Router>
    </div>
  );
  
}

export default App;
