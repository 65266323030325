import React from 'react'
import Navbar from '../components/Navbar'

function Store() {
  return (
    <div className='storePage'>
      <Navbar/>
      <div className='storeContainer'>
        <div className='storeContent'>
          <h1><i>The store is currently unavailable.</i></h1>
        </div>
      </div>
    </div>
  )
}

export default Store