import React, {Component} from "react";
import {Link} from 'react-router-dom'

import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { IconButton } from '@mui/material'

import { Col, Row, Container } from 'react-bootstrap'

const Footer = () => (
  <div className="footer">
    <div color="green accent-1"  className="page-footer navbar-inverse navbar-fixed-bottom text-success font-medium pt-4 mt-4">
                <Container className="footerContainer col-12 col-sm-12 col-md-12 col-lg-12">
                    <Row>
                        
                            <h5 className="footerTitle center-block my-10 text-center text-uppercase font-large font-weight-bold customCenter">We Save Animals</h5>
                            <p className="footerText center-block text-center">Our company is US based and carbon neutral. We use materials that are sourced responsibly and our mission is straightforward: we want to make better dog tags at a competitive price. </p>
                        
                        
                        
                        {/*
                        <Col md="2" className="footerLinks">
                            <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">Links</h5>
                            <ul className="list-unstyled ">
                                <li><a href="#!">Link 1</a></li>
                                <li><a href="#!">Link 2</a></li>
                                <li><a href="#!">Link 3</a></li>
                                <li><a href="#!">Link 4</a></li>
                            </ul>
                        </Col>
*/}
                    </Row>
                </Container>
                {/*
                <div className="text-center">
                    <ul className="list-unstyled list-inline">
                        <li className="list-inline-item"><a className="btn-floating btn-sm btn-fb mx-1"><i className="fa fa-facebook"> </i></a></li>
                        <li className="list-inline-item"><a className="btn-floating btn-sm btn-tw mx-1"><i className="fa fa-twitter"> </i></a></li>
                        <li className="list-inline-item"><a className="btn-floating btn-sm btn-gplus mx-1"><i className="fa fa-google-plus"> </i></a></li>
                        <li className="list-inline-item"><a className="btn-floating btn-sm btn-li mx-1"><i className="fa fa-linkedin"> </i></a></li>
                        <li className="list-inline-item"><a className="btn-floating btn-sm btn-dribbble mx-1"><i className="fa fa-dribbble"> </i></a></li>
                    </ul>
                </div>
    */}
    
                <div className="footer-socials">
                    <ul className='socialsFooter px-3 d-flex justify-content-center py-3 '>
                        <li>
                            <a href="https://www.instagram.com/clever.collar/" target="_blank"><InstagramIcon fontSize='large'/><span className='socLinks'></span></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/CleverCollarCo" target="_blank"><FacebookIcon fontSize='large'/><span className='socLinks'></span></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/CollarClever" target="_blank"><TwitterIcon fontSize='large'/><span className='socLinks'></span></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/clever-collar-company/" target="_blank"><LinkedInIcon fontSize='large'/><span className='socLinks'></span></a>
                        </li>
                    </ul>
                </div>

                <div className="footer-copyright text-center">
                    <Container className='pb-2' fluid>
                        Copyright &copy; {(new Date().getFullYear())}: <a href="https://clevercollarcompany.com/"> www.CleverCollarCompany.com</a>
                    </Container>
                </div>
                <div className="footer-copyright text-center">
                    <Container className='pb-2' fluid>
                        <Link to='/privacy' target='_blank'>Privacy Policy</Link><span> • </span><Link to='/cookie' target='_blank'>Cookie Policy</Link>
                    </Container>
                </div>
            </div>
  </div>
);

export default Footer;