import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

function Home() {

  document.title = "Clever Collar - Home"

  return (
    <div className='homePage'>
      <Navbar/>
      <div className='homeContainer homeContainer1'>
        <div className='homeContent1'>
          <div className='homeColumnTop'>
            <img className='largeTitle' src='logo_text.png'></img>
            <div className='smallTitleBox'>
              <img className='smallTitle' src='logo_text.png'></img>
            </div>
            <h2 className='largeh2'>Dog Tags, reimagined</h2>
            <h2 className='smallh2'>Dog Tags, reimagined</h2>
            <p>With the same technology that powers 
            Apple-Pay, we have found a way to revolutionize the process of keeping pets safe.</p>
            <a href='#about'><button className='homeButton'>Learn More</button></a>
          </div>
        </div>
      </div>
      <a id='about' className='anchorJump'>Learn More</a>
      <div className='homeContainer homeContainer2'>
        <div className='globalHeader sectionHeader'>
          <h1>WHAT IS IT?</h1>
        </div>
        <div className='homeContent homeContent2'>
          <img className='nfcLogo' src='nfc-logo-mono.png'></img>
          <p>
            We offer dog tags with embedded NFC chips. Each tag can easily be registered with you and your dog's info in case it gets lost. 
            If anyone finds your lost dog they can simply scan the tag and receive your contact info in order to return your pet home. No app required.
          </p>
        </div>
        <div className='globalHeader sectionHeader2'>
          <h1>WHY CHOOSE US?</h1>
        </div>
        <div className='homeContent homeContent3'>
          <div className='homeCard'>
            <h1 className='cardTitle'>It's more for less</h1>
            <p>
              The average metal dog tag costs right around $10. That means, on average, our tags cost less than a normal dog tag. You're paying less 
              for the same thing, but with an extra layer of security.
            </p>
          </div>
          <div className='homeCard'>
            <h1 className='cardTitle'>No charging required</h1>
            <p>
              Most smart dog tags are large, bulky and require a battery. However, Clever Collar dog tags are slim and seamless with no battery required. 
              The chip utilizes magnetic induction to power itself.
            </p>
          </div>
          <div className='homeCard'>
            <h1 className='cardTitle'>Lifetime warranty</h1>
            <p>
              We are confident that our tags are durable, reliable, and will last a lifetime. However, if anything ends up happening to your tag, we have you covered. 
              Simply contact our team we will get you a new one.
            </p>
          </div>
        </div>
      </div>
      <div className='homeContainer homeContainer3'>
        <div className='homeContent homeContent3 homeContent4'>
          <Link to='/product'>
            <button className='homeAboutButton'>View Product</button>
          </Link>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Home