import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useState } from 'react';
import { send } from 'emailjs-com';
import FAQ from '../components/FAQ'

import { Accordion, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Contact() {

  document.title = "Clever Collar - Contact Us"

  const [toSend, setToSend] = useState({
    first_name: '',
    last_name: '',
    email: '',
    question: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_65vars9',
      'template_0a046mw',
      toSend,
      'q5z8hhoGdXr2Gc9DJ'
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    })
    .catch((err) => {
      console.log('FAILED...', err);
    });

    setToSend({
      first_name: '',
      last_name: '',
      email: '',
      question: '',
    })
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className='contactPage'>
      <Navbar/>
      <div className='contactContainer contactContainer2'>
        <div className='globalHeader contactHeader'>
          <h1>FAQ</h1>
        </div>
        <div className='contactContent contactContent2'>
          <FAQ/>
        </div>
      </div>
      <div className='contactContainer'>
        <div className='globalHeader contactHeader'>
          <h1>ANY QUESTIONS?</h1>
        </div>
        <div className='contactContent'>
          <form id='contactForm' className='contactForm' onSubmit={onSubmit}>
            <div className='formRow'>
              <label className='formLabel' >First Name:</label><br/>
              <input className='formInput' type='text' name='first_name' placeholder='John' value={toSend.first_name} onChange={handleChange} required></input>
            </div>
            <div className='formRow'>
              <label className='formLabel'>Last Name:</label><br/>
              <input className='formInput' type='text' name='last_name' placeholder='Doe' value={toSend.last_name} onChange={handleChange} required></input>
            </div>
            <div className='formRow'>
              <label className='formLabel'>Email:</label><br/>
              <input className='formInput' type='email' name='email' placeholder='johndoe@gmail.com' value={toSend.email} onChange={handleChange} required></input>
            </div>
            <div className='formRow'>
              <label className='formLabel'>Question:</label><br/>
              <textarea className='questionInput' maxlength='700' type='text' name='question' placeholder='Type your question here...' value={toSend.question} onChange={handleChange} required></textarea>
            </div>
            <div className='formRow2'>
              <button type='submit' className='contactButton'>Submit</button>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Contact