import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

function Product() {

  document.title = "Clever Collar - Our Product"

  return (
    <div className='productPage'>
      <Navbar/>
      <div className='globalHeader productHeader'>
        <h1>OUR PRODUCT</h1>
      </div>
      <div className='productContainer productContainer3'>
        <div className='productContent productContent5 productContent7'>
          <div className='productIconBox'>
            <img className='productIcon' src='icons (4).png'></img>
            <h1 className='productText'>No battery</h1>
            <p>Our design does not utilize any sort of battery for power, this means the Clever Tag is still just as durable, water resistance and hassle-free as any normal dog tag.</p>
          </div>
          <div className='productIconBox'>
            <img className='productIcon' src='icons (2).png'></img>
            <h1 className='productText'>No app download</h1>
            <p>Anyone who finds your pet can easily scan the tag without needing to download an app. However, an app is still required for setup and adding additional information.</p>
          </div>
        </div>
      </div>
      <div className='productContainer productContainer3'>
        <div className='productContent productContent5 productContent6'>
          <div className='productIconBox'>
            <img className='productIcon' src='icons (1).png'></img>
            <h1 className='productText'>It's more for less</h1>
            <p>Despite costing less than a normal dog tag, the Clever Tag is packed with many more features and customizability that make it a lot more bang for your buck.</p>
          </div>
          <div className='productIconBox'>
            <img className='productIcon' src='icons (3).png'></img>
            <h1 className='productText'>Sleek and lightweight</h1>
            <p>Our dog tags do not contain any unnecessary hardware that takes up more space than needed. This means they are slim and seamless while still functioning as intended.</p>
          </div>
        </div>
      </div>
      <div className='productContainer productContainer6'>
        <div className='productContent8'>
          <div className='productQuote'>
            <h1>10 million pets are lost in the United States each year, we want to save them all!</h1>
          </div>
        </div>
      </div>
      <div className='globalHeader productHeader'>
        <h1>HOW ARE WE DIFFERENT?</h1>
      </div>
      <div className='productContainer productContainer3'>
        <div className='productContent'>
          <img className='productImage' src='product-image5.jpg'></img>
          <div className='productParagraph'>
            <h1>Same durability, better capability</h1>
            <p>
              Dog tags have functioned the same way since the 1970s - we made them better. Our product allows for easier access and larger storage than standard dog tags. 
            </p>
          </div>
        </div>
        <div className='productContent'>
          <div className='productParagraph2'>
            <h1>Your personal information is safe</h1>
            <p>
              We ensure your protection by using a design that securely transfers the needed information in order to bring your furry family member home, but doesn’t put you at risk otherwise. If your dog is lost, one needs to tap the tag in order to access the information, compared to other tags where your personal information is easily viewable. 
            </p>
          </div>
          <img className='productImage' src='product-image7.png'></img>
        </div>
        {/*<div className='productContent'>
          <div className='productParagraph2'>
            <h1>The market is growing</h1>
            <p>
              The NFC chip market size is expected to grow from $8.71 billion in 2021 to $17.82 billion by 2028; it is estimated to grow at a GAGR of 10.8% during 2021-2028.
            </p>
          </div>
          <img className='productImage' src='product-image1.jpg'></img>
        </div>*/}
        <div className='productContent'>
          <img className='productImage' src='product-image6.jpg'></img>
          <div className='productParagraph2'>
            <h1>Here's the bottom line</h1>
            <p>
              The clever company makes smart tags that are cheaper, smarter and easier. We used the power of the web to improve a conventional product without the hustle of charging and setting things up.
            </p>
          </div>
        </div>
        <div className='productContainer productContainer2'>
          <div className='productContent productContent2'>
            <Link to='/about'>
              <button className='productAboutButton'>Meet The Team</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Product