import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SimpleAccordion() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>How does the tag work without a battery?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The tag is powered by NFC technology that utilizes magnetic induction and radio waves to power itself and send information. It is only activated when held near a device and otherwise does not require power.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Do I need to charge my tag?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Since our tags do not utilize batteries, you do not need to charge it. It's as easy as any traditional dog tag, just attach it to your pet's collar and you're done!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Where can I buy a tag?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We are currently working to get our tags into stores across America, sit tight!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How close do I need to be to scan the tag?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You need to be close enough to tap your phone against either side of the tag in order to read it, however, you can still keep a safe distance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>My tag doesn't work, what should I do?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If your tag doesn't work or is broken for whatever reason, please contact us in the form below. We will work to replace your tag as soon as possible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>I bought a tag, how do I register it?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you have an un-registered tag simply scan it with your iPhone and navigate to our mobile app to register it.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
