import React, { useRef } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";

//icons import
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

function Header() { 

    //for burger toggle
    const burger = useRef(null);
  
    const hideMenu = () => {
        burger.current.style.right = '-300px';
    };
  
    const showMenu = () => {
        burger.current.style.right = '0px';
    };

    return (
        <div className='app-navbar'>
            <nav>
                <Link className='logo' to='/'><img className='logoImage' src='Logo_lightgreen.png'></img></Link>
                <button className='menuIcon' onClick={showMenu}><MenuRoundedIcon fontSize='large'></MenuRoundedIcon></button>
                <div ref={burger} className='burger-navbar'>
                    <button className='closeIcon' onClick={hideMenu}><CloseRoundedIcon fontSize='large'></CloseRoundedIcon></button>
                    <div className='burgerList'>
                        <ul>
                            <li>
                                <Link to='/' onClick={hideMenu}><HomeOutlinedIcon fontSize='small'/><span className='navLinks'>Home</span></Link>
                            </li>
                            {/*<li>
                                <Link to='/store' onClick={hideMenu}><LocalGroceryStoreOutlinedIcon fontSize='small'/><span className='navLinks'>Store</span></Link>
                            </li>*/}
                            <li>
                                <Link to='/product' onClick={hideMenu}><StyleOutlinedIcon fontSize='small'/><span className='navLinks'>Our Product</span></Link>
                            </li>
                            <li>
                                <Link to='/about' onClick={hideMenu}><PeopleAltOutlinedIcon fontSize='small'/><span className='navLinks'>About Us</span></Link>
                            </li>
                            <li>
                                <Link to='/contact' onClick={hideMenu}><LiveHelpOutlinedIcon fontSize='small'/><span className='navLinks'>Contact Us</span></Link>
                            </li>
                            {/*<li>
                                <Link to='/tags' onClick={hideMenu}><StyleOutlinedIcon fontSize='small'/><span className='navLinks'>Tags</span></Link>
                            </li>*/}
                            <li>
                                <Link to='/' onClick={hideMenu}><LoginRoundedIcon fontSize='small'/><span className='navLinks'>Open App</span></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul className='navbar'>
                    <li>
                        <Link to='/'><HomeOutlinedIcon fontSize='small'/><span className='navLinks'>Home</span></Link>
                    </li>
                    {/*<li>
                        <Link to='/store'><LocalGroceryStoreOutlinedIcon fontSize='small'/><span className='navLinks'>Store</span></Link>
                    </li>*/}
                    <li>
                        <Link to='/product'><StyleOutlinedIcon fontSize='small'/><span className='navLinks'>Our Product</span></Link>
                    </li>
                    {/* <li>
                        <Link to='/about'><PeopleAltOutlinedIcon fontSize='small'/><span className='navLinks'>About Us</span></Link>
                    </li> */}
                    <li>
                        <Link to='/contact'><LiveHelpOutlinedIcon fontSize='small'/><span className='navLinks'>Contact Us</span></Link>
                    </li>
                    {/*<li>
                        <Link to='/tags'><StyleOutlinedIcon fontSize='small'/><span className='navLinks'>Tags</span></Link>
                    </li>*/}
                </ul>
                <ul class="login">
                    <li>
                        <Link to='/'><button type="button" className='loginButton'>Open App</button></Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Header