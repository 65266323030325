import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../components/Navbar'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

function Tag() {
  
  //grabs id from the url
  const { id } = useParams();

  const TagData = () => {

    const [data, setData] = React.useState('Loading tag data...')

    const url = 'https://628c465b3df57e983ecc084b.mockapi.io/api/v1/tag/' + id;

    let jsonData;
    

    useEffect(() => {
      //if there is an id
      if(id) {
        //then fetch the url
        fetch(url)
        .then(response => response.json())
        .then((json) => {
          //then set "data" to the response
          //parsedJsonData = JSON.parse(jsonData);

          setData(json);
          

          ////////////////////Parsing JSON
          //parsedJsonData = JSON.parse(data);
          //console.log(parsedJsonData);
          //console.log(JSON.stringify(data));

        })
        .catch((error) => {
          data = {};
          console.log(error);
        })
      }

      //otherwise set "data" to nothing
      else {
        setData()
      }
    })


    //page if there is data
    return (
      <div className='tagsIdPage'>
        <div className='tagsIdContainer'>
          <div className='tagsIdHeader'>
            <span>Powered by <Link to='/'>The Clever Company</Link></span>
          </div>
          <div className='dogName'>
            <h1 className='dogNameText'>Hello, I'm <span>{data.tagName}</span></h1>
          </div>
          <div className='tagsIdContent'>
            <img className='dogImage' src='/dog-placeholder.jpg'></img>
            {/* <div>Tag Name: {data.tagName}</div>
            <div>Tag Email: {data.tagEmail}</div>
            <div>Tag Notes: {data.tagNotes}</div>
            <div>Contact Name: {data.contactName}</div>
            <div>Contact Number: {data.contactNumber}</div>
            <div>Contact Email: {data.contactEmail}</div>
            <div>ID Number: {data.id}</div>
            <div>Metadata: {data.metadata}</div> */}
          </div>
          <div className='tagsIdContent2'>
            <h1 className='dogNameText2'>STATUS: <span className='redText'>LOST</span></h1>
          </div>
          <div className='tagsIdContent4'>
            <h1 className='dogNameText2'>REWARD: <span className='blueText'>$100</span></h1>
          </div>
          <div className='tagsIdContent3'>
            <h1 className='dogNameText3'>My owner is <span>{data.contactName}</span></h1>
            <h1 className='dogNameText3'>Please call them at <a className='blueText'>{data.contactNumber}</a></h1>
            <h1 className='dogNameText3'>Or email them at <a className='blueText'>{data.contactEmail}</a></h1>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {(() => {
        if(id) {
          return (
            <TagData/>
          )
        } 
        else {
          return (
            <div className='tagsPage'>
              <Navbar/>
              <div className='tagsContainer'>
                <div className='tagsContent'>
                  <h1>This is the Tag Page</h1>
                  <h2>Change url id for data</h2>
                </div>
              </div>
            </div>
          )
        }
      })()}
    </div>
  )
}

export default Tag
